export const sliderMetadata = [{
  cm_preferredWidth: 1440,
  cm_responsiveDevices: {
    mobile_portrait: {
      height: 736,
      order: 0,
      width: 414
    },
    mobile_landscape: {
      width: 736,
      height: 414,
      order: 1
    },
    tablet_portrait: {
      width: 768,
      height: 1024,
      order: 2
    },
    tablet_landscape: {
      width: 1024,
      height: 768,
      order: 3
    },
    large: {
      width: 1440,
      height: 900,
      order: 4
    },
    fullHD: {
      width: 1920,
      height: 1080,
      order: 5
    }
  }
}]
